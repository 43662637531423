<template>
    <seccion-datos 
        :titulo="costesmedios" 
        @cambios="guardar($data)" 
        >
        <div class="row">
            <div class="col-md-4">
                <label>{{$t('general.costemediopermitido')}}</label>
                <div class="input-group">                                                
                    <input 
                    type="number" 
                    class="form-control form-control-sm" 
                    id="coste-medio-permitido" 
                    placeholder=""
                    v-model="coste_medio"
                    >
                    <div class="input-group-append">
                        <span class="input-group-text form-control-sm">&euro;</span>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <label>{{$t('general.costemediotipo')}}</label>
                <select 
                    class="custom-select form-control-sm"
                    v-model="coste_medio_tipo_companyia"
                    >
                    <option>{{$t('general.seleccionetipodano')}}</option>
                    <option>Agua</option>
                    <option>Viento</option>  
                    <option>----</option>                                    
                </select>
            </div>
            <div class="col-md-4">
                <label>{{$t('general.impuesto')}}</label>
                <select 
                    class="custom-select form-control-sm"
                    v-model="costes_medios_impuesto"
                    >
                    <option value="0">{{$t('general.sin')}} IVA/IGIC</option>
                    <option value="1">{{$t('general.con')}} IVA/IGIC</option>  
                </select>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-secondary card-outline">                            
                    <div class="card-body ">
                        <h5>{{$t('general.intervalos')}}</h5>
                        <compania-facturacion-costes-intervalos />
                    </div>
                </div>
            </div>

        </div>
    </seccion-datos>
</template>

<script>
import CompaniaFacturacionCostesIntervalos from './CompaniaFacturacionCostesIntervalos.vue';

export default {
    components: {
        CompaniaFacturacionCostesIntervalos
    },
    props: {
        datos: {
            type: Object
        },
    },
    inject: ['guardar'],
    data() {
        return  {
            coste_medio: null,
            coste_medio_max_companyia: null,
            coste_medio_min_companyia: null,
            coste_medio_tipo_companyia: null,
            costes_medios_impuesto: null,
            costesmedios: 'Costes medios',
        }
    },
    watch: {
        datos() {
            this.init();
        }
    },
    methods: {
        init() {
            if (this.datos) {
                for (let dato in this.$data) {
                    if (this.datos[dato] !== null && this.datos[dato] !== undefined) {
                        this[dato] = this.datos[dato];
                    }
                }
            }
        },
    },
    created() {
        this.init();
        this.costesmedios = this.$t('general.costesmedios');
    },
}
</script>